const app = new Vue({
    el: '.mod_garant_configurator form',
    data: {
        safeModel: '',
        safeSecurity: '',
        safeClosure: '',
        safeWidth: '',
        safeHeight: '',
        safeDepth: '',
        safeColor: 'ral7035',
        weight: 0,
        error: [],
        maxDimension: {
            "bodentresor": {
                "A": {
                    "doppelbartschloss": {
                        "minHeight": 110,
                        "maxHeight": 600,
                        "minWidth": 220,
                        "maxWidth": 600,
                        "minDepth": 160,
                        "maxDepth": 600
                    },
                    "mechanischesschloss": {
                        "minHeight": 110,
                        "maxHeight": 600,
                        "minWidth": 220,
                        "maxWidth": 600,
                        "minDepth": 160,
                        "maxDepth": 600
                    },
                    "elektronikschloss": {
                        "minHeight": 110,
                        "maxHeight": 600,
                        "minWidth": 220,
                        "maxWidth": 600,
                        "minDepth": 160,
                        "maxDepth": 600
                    },
                    "elektronikschlossnotschluessel": {
                        "minHeight": 220,
                        "maxHeight": 600,
                        "minWidth": 220,
                        "maxWidth": 600,
                        "minDepth": 220,
                        "maxDepth": 600
                    }
                },
                "B": {
                    "doppelbartschloss": {
                        "minHeight": 200,
                        "maxHeight": 600,
                        "minWidth": 250,
                        "maxWidth": 600,
                        "minDepth": 220,
                        "maxDepth": 600
                    },
                    "mechanischesschloss": {
                        "minHeight": 200,
                        "maxHeight": 600,
                        "minWidth": 390,
                        "maxWidth": 600,
                        "minDepth": 220,
                        "maxDepth": 600
                    },
                    "elektronikschloss": {
                        "minHeight": 200,
                        "maxHeight": 600,
                        "minWidth": 390,
                        "maxWidth": 600,
                        "minDepth": 220,
                        "maxDepth": 600
                    },
                    "elektronikschlossnotschluessel": {
                        "minHeight": 200,
                        "maxHeight": 600,
                        "minWidth": 390,
                        "maxWidth": 600,
                        "minDepth": 220,
                        "maxDepth": 600
                    }
                }
            },
            "moebeltresor": {
                "A": {
                    "doppelbartschloss": {
                        "minHeight": 200,
                        "maxHeight": 2000,
                        "minWidth": 250,
                        "maxWidth": 800,
                        "minDepth": 160,
                        "maxDepth": 800
                    },
                    "mechanischesschloss": {
                        "minHeight": 200,
                        "maxHeight": 2000,
                        "minWidth": 390,
                        "maxWidth": 800,
                        "minDepth": 160,
                        "maxDepth": 800
                    },
                    "elektronikschloss": {
                        "minHeight": 200,
                        "maxHeight": 2000,
                        "minWidth": 390,
                        "maxWidth": 800,
                        "minDepth": 160,
                        "maxDepth": 800
                    },
                    "elektronikschlossnotschluessel": {
                        "minHeight": 200,
                        "maxHeight": 2000,
                        "minWidth": 390,
                        "maxWidth": 800,
                        "minDepth": 160,
                        "maxDepth": 800
                    }
                },
                "B": {
                    "doppelbartschloss": {
                        "minHeight": 200,
                        "maxHeight": 2000,
                        "minWidth": 250,
                        "maxWidth": 900,
                        "minDepth": 200,
                        "maxDepth": 800
                    },
                    "mechanischesschloss": {
                        "minHeight": 200,
                        "maxHeight": 2000,
                        "minWidth": 390,
                        "maxWidth": 900,
                        "minDepth": 200,
                        "maxDepth": 800
                    },
                    "elektronikschloss": {
                        "minHeight": 200,
                        "maxHeight": 2000,
                        "minWidth": 390,
                        "maxWidth": 900,
                        "minDepth": 200,
                        "maxDepth": 800
                    },
                    "elektronikschlossnotschluessel": {
                        "minHeight": 200,
                        "maxHeight": 2000,
                        "minWidth": 390,
                        "maxWidth": 900,
                        "minDepth": 200,
                        "maxDepth": 800
                    }
                }
            },
            "standtresor": {
                "B": {
                    "doppelbartschloss": {
                        "minHeight": 350,
                        "maxHeight": 2000,
                        "minWidth": 350,
                        "maxWidth": 900,
                        "minDepth": 300,
                        "maxDepth": 800
                    },
                    "mechanischesschloss": {
                        "minHeight": 350,
                        "maxHeight": 2000,
                        "minWidth": 410,
                        "maxWidth": 900,
                        "minDepth": 300,
                        "maxDepth": 800
                    },
                    "elektronikschloss": {
                        "minHeight": 350,
                        "maxHeight": 2000,
                        "minWidth": 410,
                        "maxWidth": 900,
                        "minDepth": 300,
                        "maxDepth": 800
                    },
                    "elektronikschlossnotschluessel": {
                        "minHeight": 350,
                        "maxHeight": 2000,
                        "minWidth": 410,
                        "maxWidth": 900,
                        "minDepth": 300,
                        "maxDepth": 800
                    }
                }
            },
            "wandtresor": {
                "A": {
                    "doppelbartschloss": {
                        "minHeight": 160,
                        "maxHeight": 800,
                        "minWidth": 220,
                        "maxWidth": 800,
                        "minDepth": 110,
                        "maxDepth": 800
                    },
                    "mechanischesschloss": {
                        "minHeight": 160,
                        "maxHeight": 800,
                        "minWidth": 220,
                        "maxWidth": 800,
                        "minDepth": 110,
                        "maxDepth": 800
                    },
                    "elektronikschloss": {
                        "minHeight": 160,
                        "maxHeight": 800,
                        "minWidth": 220,
                        "maxWidth": 800,
                        "minDepth": 110,
                        "maxDepth": 800
                    },
                    "elektronikschlossnotschluessel": {
                        "minHeight": 160,
                        "maxHeight": 800,
                        "minWidth": 220,
                        "maxWidth": 800,
                        "minDepth": 110,
                        "maxDepth": 800
                    }
                },
                "B": {
                    "doppelbartschloss": {
                        "minHeight": 240,
                        "maxHeight": 1200,
                        "minWidth": 250,
                        "maxWidth": 900,
                        "minDepth": 160,
                        "maxDepth": 800
                    },
                    "mechanischesschloss": {
                        "minHeight": 240,
                        "maxHeight": 1200,
                        "minWidth": 390,
                        "maxWidth": 900,
                        "minDepth": 160,
                        "maxDepth": 800
                    },
                    "elektronikschloss": {
                        "minHeight": 240,
                        "maxHeight": 1200,
                        "minWidth": 390,
                        "maxWidth": 900,
                        "minDepth": 160,
                        "maxDepth": 800
                    },
                    "elektronikschlossnotschluessel": {
                        "minHeight": 350,
                        "maxHeight": 1200,
                        "minWidth": 390,
                        "maxWidth": 900,
                        "minDepth": 160,
                        "maxDepth": 800
                    }
                }
            }
        }
    },
    computed: {
        price: function () {
            return this.calcPrice()
        },
        displayWeight: function () {
            return this.weight.toFixed(2)
        }
    },
    methods: {
        calcPrice: function () {
            if (this.checkDimension()) {
                drawSafe(parseInt(this.safeHeight), parseInt(this.safeWidth), parseInt(this.safeDepth), this.safeClosure, this.safeModel, this.safeSecurity);

                const x = {
                    bodentresor: {
                        A: {
                            x1: 6,
                            x2: 6,
                            x3: 7,
                            twoKeyClosure: 7.85
                        },
                        B: {
                            x1: 6,
                            x2: 6,
                            x3: 11,
                            twoKeyClosure: 22.80
                        }
                    },
                    moebeltresor: {
                        A: {
                            x1: 9,
                            x2: 6,
                            x3: 6,
                            twoKeyClosure: 22.80
                        },
                        B: {
                            x1: 15,
                            x2: 10,
                            x3: 10,
                            twoKeyClosure: 22.80
                        }
                    },
                    standtresor: {
                        B: {
                            x1: 16,
                            x2: 16,
                            x3: 12,
                            twoKeyClosure: 19.39
                        }
                    },
                    wandtresor: {
                        A: {
                            x1: 7,
                            x2: 6,
                            x3: 6,
                            twoKeyClosure: 7.85
                        },
                        B: {
                            x1: 13,
                            x2: 6,
                            x3: 6,
                            twoKeyClosure: 22.80
                        }
                    }
                };
                var workDuration = 0;
                const priceWorkingHour = 25;
                var priceCollar = 0;
                var priceClosure = 0;

                if (this.safeModel === 'bodentresor') {
                    if (this.safeSecurity === 'A') {
                        workDuration = calcWorkDuration(this.safeWidth, this.safeDepth, 35000, 1.5, 55000, 2, 144000, 2.5, 3);
                    } else if (this.safeSecurity === 'B') {
                        workDuration = calcWorkDuration(this.safeWidth, this.safeDepth, 73000, 3.5, 105000, 4, 185000, 4.5, 5);
                    }
                } else if (this.safeModel === 'moebeltresor') {
                    if (this.safeSecurity === 'A') {
                        workDuration = calcWorkDuration(this.safeHeight, this.safeWidth, 200000, 4, 390000, 4.5, 585000, 5, 6);
                        priceCollar = 2.07;
                    } else if (this.safeSecurity === 'B') {
                        workDuration = calcWorkDuration(this.safeHeight, this.safeWidth, 200000, 4.5, 390000, 5, 585000, 5.5, 6.5);
                        priceCollar = 2.07;
                    }
                } else if (this.safeModel === 'standtresor') {
                    if (this.safeSecurity === 'A') {
                        workDuration = 0;
                        priceCollar = 0;
                    } else if (this.safeSecurity === 'B') {
                        workDuration = calcWorkDuration(this.safeHeight, this.safeWidth, 490000, 7, 730000, 8, 975000, 8.5, 9);
                        priceCollar = 18.64;
                    }
                } else if (this.safeModel === 'wandtresor') {
                    if (this.safeSecurity === 'A') {
                        workDuration = calcWorkDuration(this.safeHeight, this.safeWidth, 35000, 1.5, 115000, 2, 225000, 2.5, 3);
                        priceCollar = 2.07;
                    } else if (this.safeSecurity === 'B') {
                        workDuration = calcWorkDuration(this.safeHeight, this.safeWidth, 200000, 4, 290000, 4.5, 435000, 5, 5.5);
                        priceCollar = 2.07;
                    }
                }

                if (undefined !== x[this.safeModel][this.safeSecurity]) {
                    this.weight = calcWeight(this.safeHeight, this.safeWidth, this.safeDepth, x[this.safeModel][this.safeSecurity]);
                    priceClosure = calcClosure(this.safeClosure, x[this.safeModel][this.safeSecurity].twoKeyClosure);
                }

                return (((this.weight * 0.9 + workDuration * priceWorkingHour + priceCollar) * 1.36 * 1.25 * 1.15 + priceClosure) * 1.19).toFixed(0);
            } else {
                return 0;
            }
        },
        checkDimension: function () {
            this.error = [];

            if (this.safeModel === '' || this.safeSecurity === '' || this.safeClosure === '') {
                this.error.push('Bitte wählen Sie ein Modell, eine Sicherheitsstufe und einen Verschluss aus.');
                return false;
            }

            if (this.safeModel === 'standtresor' && this.safeSecurity === 'A') {
                this.error.push('Der Standtresor ist nur in Sicherheitsstufe B verfügbar.');
                this.safeSecurity = 'B';
            }

            if (this.safeWidth < this.maxDimension[this.safeModel][this.safeSecurity][this.safeClosure]['minWidth']) {
                this.error.push('Die minimale Breite beträgt ' + this.maxDimension[this.safeModel][this.safeSecurity][this.safeClosure]['minWidth'] + 'mm');
            }
            if (this.safeWidth > this.maxDimension[this.safeModel][this.safeSecurity][this.safeClosure]['maxWidth']) {
                this.error.push('Die maximale Breite beträgt ' + this.maxDimension[this.safeModel][this.safeSecurity][this.safeClosure]['maxWidth'] + 'mm');
            }
            if (this.safeHeight < this.maxDimension[this.safeModel][this.safeSecurity][this.safeClosure]['minHeight']) {
                this.error.push('Die minimale Höhe beträgt ' + this.maxDimension[this.safeModel][this.safeSecurity][this.safeClosure]['minHeight'] + 'mm');
            }
            if (this.safeHeight > this.maxDimension[this.safeModel][this.safeSecurity][this.safeClosure]['maxHeight']) {
                this.error.push('Die maximale Höhe beträgt ' + this.maxDimension[this.safeModel][this.safeSecurity][this.safeClosure]['maxHeight'] + 'mm');
            }
            if (this.safeDepth < this.maxDimension[this.safeModel][this.safeSecurity][this.safeClosure]['minDepth']) {
                this.error.push('Die minimale Tiefe beträgt ' + this.maxDimension[this.safeModel][this.safeSecurity][this.safeClosure]['minDepth'] + 'mm');
            }
            if (this.safeDepth > this.maxDimension[this.safeModel][this.safeSecurity][this.safeClosure]['maxDepth']) {
                this.error.push('Die maximale Tiefe beträgt ' + this.maxDimension[this.safeModel][this.safeSecurity][this.safeClosure]['maxDepth'] + 'mm');
            }
            return this.error.length === 0;

        },
        changeColor: function (color) {
            this.safeColor = color;
        }
    }
});

function calcWeight(height, width, depth, factor) {
    const heightWidth = height / 1000 * width / 1000 * factor.x1;
    const heightDepth = height / 1000 * depth / 1000 * factor.x2;
    const widthDepth = width / 1000 * depth / 1000 * factor.x3;

    return (heightWidth + heightDepth + widthDepth) * 8;
}

function calcWorkDuration(value1, value2, step1, duration1, step2, duration2, step3, duration3, duration4) {
    if (value1 * value2 <= step1) {
        workDuration = duration1;
    } else if (value1 * value2 <= step2) {
        workDuration = duration2;
    } else if (value1 * value2 <= step3) {
        workDuration = duration3;
    } else {
        workDuration = duration4;
    }
    return workDuration;
}

function calcClosure(closure, priceTwoKey) {
    if (closure === 'doppelbartschloss') {
        return priceTwoKey;
    } else if (closure === 'mechanischesschloss') {
        return 110;
    } else if (closure === 'elektronikschloss') {
        return 180;
    } else if (closure === 'elektronikschlossnotschluessel') {
        return 250;
    }
}

function drawSafe(objectHeight, objectWidth, objectDepth, closure, model, security) {
    var maxSize = 400;

    var height = objectHeight;
    var width = objectWidth;
    var depth = objectDepth;

    var depthWidth = depth / 2 * Math.sin(45 * Math.PI / 180);
    var depthHeight = depth / 2 * Math.sin(45 * Math.PI / 180);
    var maxWidth = width + depthWidth;
    var maxHeight = height + depthHeight;
    var centerFrontX = width / 2;
    var centerFrontY = maxHeight - height / 2;
    var startWidth = 0;

    var hingeWidth = 0.1;
    var hingeHeight = 0.07;
    var offset = 0.1;
    var doorOffset = width * hingeWidth / 2;

    var draw = SVG('safe').size(maxSize, maxSize);
    draw.clear();

    var group = draw.group();

    if (model === 'wandtresor') {
        var halfOffset = doorOffset / 2;
        group.polygon([
            [startWidth + depthWidth - halfOffset, maxHeight - height - depthHeight - halfOffset],
            [startWidth + depthWidth + width + halfOffset, maxHeight - height - depthHeight - halfOffset],
            [startWidth + depthWidth + width + halfOffset, maxHeight - depthHeight + halfOffset],
            [startWidth + depthWidth - halfOffset, maxHeight - depthHeight + halfOffset]
        ]).fill('transparent').attr('class', 'front');
    }

    var top = group.polygon([
        [startWidth, maxHeight - height],
        [startWidth + depthWidth, maxHeight - height - depthHeight],
        [startWidth + width + depthWidth, maxHeight - height - depthHeight],
        [startWidth + width, maxHeight - height],
    ]);
    if (model === 'bodentresor') {
        top.fill('transparent').attr('class', 'front');
        var topDimension = top.bbox();
        centerFrontX = topDimension.cx;
        centerFrontY = topDimension.cy;

        if (security === 'A') {
            var borderTop = top.clone();
            borderTop.transform({scale: 1.1});
        }

        var borderBottom = top.clone();
        borderBottom.dy(height);
        borderBottom.transform({scale: 1.1});
        group.add(borderBottom);
    } else {
        top.fill('transparent').attr('class', 'top');
    }

    group.polygon([
        [startWidth + width, maxHeight - height],
        [startWidth + width + depthWidth, maxHeight - height - depthHeight],
        [startWidth + width + depthWidth, maxHeight - depthHeight],
        [startWidth + width, maxHeight],
    ]).fill('transparent').attr('class', 'side right');

    if (model === 'wandtresor') {
        group.polygon([
            [startWidth - doorOffset, maxHeight + doorOffset],
            [startWidth + width + doorOffset, maxHeight + doorOffset],
            [startWidth + width + doorOffset, maxHeight - height - doorOffset],
            [startWidth - doorOffset, maxHeight - height - doorOffset]
        ]).fill('transparent').attr('class', 'front');
    }

    var front = group.polygon([
        [startWidth, maxHeight],
        [startWidth + width, maxHeight],
        [startWidth + width, maxHeight - height],
        [startWidth, maxHeight - height]
    ]).fill('transparent').attr('class', 'front');
    if (model === 'bodentresor') {
        front.fill('transparent').attr('class', 'top');
        if (security === 'A') {
            borderTop.front();
        }
        top.front();
    } else {
        front.fill('transparent').attr('class', 'front');
    }

    if (model === 'standtresor' || model === 'moebeltresor') {
        group.polygon([
            [startWidth + doorOffset, maxHeight - doorOffset],
            [startWidth + width - doorOffset, maxHeight - doorOffset],
            [startWidth + width - doorOffset, maxHeight - height + doorOffset],
            [startWidth + doorOffset, maxHeight - height + doorOffset]
        ]).fill('transparent').attr('class', 'front');
    }

    /** Hinge */
    if (model === 'standtresor') {
        // bottom
        group.polygon([
            [startWidth + width - width * hingeWidth, maxHeight - height * offset],
            [startWidth + width, maxHeight - height * offset],
            [startWidth + width, maxHeight - height * offset - height * hingeHeight],
            [startWidth + width - width * hingeWidth, maxHeight - height * offset - height * hingeHeight]
        ]).fill('transparent').attr('class', 'hinge');

        // top
        group.polygon([
            [startWidth + width - width * hingeWidth, maxHeight - height + height * offset],
            [startWidth + width, maxHeight - height + height * offset],
            [startWidth + width, maxHeight - height + height * offset + height * hingeHeight],
            [startWidth + width - width * hingeWidth, maxHeight - height + height * offset + height * hingeHeight]
        ]).fill('transparent').attr('class', 'hinge');
    }

    if (closure === 'doppelbartschloss') {
        var twoWayClosure = group.group();
        twoWayClosure.path('M24.5,0C16.2,0,9.4,6.8,9.4,15.1c0,8.3,6.7,15.1,15.1,15.1s15.1-6.8,15.1-15.1C39.6,6.8,32.8,0,24.5,0z M24.5,27.7c-6.8,0-12.2-5.6-12.2-12.6c0-7,5.5-12.6,12.2-12.6s12.2,5.6,12.2,12.6C36.7,22.1,31.3,27.7,24.5,27.7z');
        twoWayClosure.rect(5, 22).x(22).y(27.7).attr('class', 'fill');
        twoWayClosure.rect(2.5, 13.8).x(18.8).y(41.8).transform({rotation: 90}).attr('class', 'fill');
        twoWayClosure.rect(2.5, 13.5).x(18.7).y(37).transform({rotation: 90}).attr('class', 'fill');
        twoWayClosure.attr('class', 'closure twoWayClosure');
        twoWayClosure.center(centerFrontX, centerFrontY);
    } else if (closure === 'elektronikschloss') {
        var electricClosure = group.group();
        electricClosure.path('M4.9,0v49.7H46V0H4.9z M43.6,46.8H7.3V2.9h36.3V46.8z');
        electricClosure.rect(6.5, 6).x(12.7).y(6.6).attr('class', 'fill');
        electricClosure.rect(6.5, 6).x(22.2).y(6.6).attr('class', 'fill');
        electricClosure.rect(6.5, 6).x(31.7).y(6.6).attr('class', 'fill');
        electricClosure.rect(6.5, 6).x(12.7).y(14.6).attr('class', 'fill');
        electricClosure.rect(6.5, 6).x(22.2).y(14.6).attr('class', 'fill');
        electricClosure.rect(6.5, 6).x(31.7).y(14.6).attr('class', 'fill');
        electricClosure.rect(6.5, 6).x(12.7).y(22.5).attr('class', 'fill');
        electricClosure.rect(6.5, 6).x(22.2).y(22.5).attr('class', 'fill');
        electricClosure.rect(6.5, 6).x(31.7).y(22.5).attr('class', 'fill');
        electricClosure.rect(6.5, 6).x(12.7).y(30.5).attr('class', 'fill');
        electricClosure.rect(6.5, 6).x(22.2).y(30.5).attr('class', 'fill');
        electricClosure.rect(6.5, 6).x(31.7).y(30.5).attr('class', 'fill');
        electricClosure.attr('class', 'closure electricClosure');
        electricClosure.center(centerFrontX, centerFrontY);
    } else if (closure === 'elektronikschlossnotschluessel') {
        var closure3 = group.group();
        closure3.rect(20, 6).x(25).y(0).transform({rotation: 90}).attr('class', 'fill');
        closure3.rect(20, 6).x(40).y(35).transform({rotation: 45}).attr('class', 'fill');
        closure3.rect(20, 6).x(10).y(35).transform({rotation: 135}).attr('class', 'fill');
        closure3.circle(10).cx(35).cy(22.5);
        closure3.attr('class', 'closure electricMechanicClosure');
        closure3.center(centerFrontX, centerFrontY);
    } else if (closure === 'mechanischesschloss') {
        var closure4 = group.group();
        closure4.path('M25,0.5C11.5,0.5,0.5,11.5,0.5,25c0,13.5,11,24.5,24.5,24.5s24.5-11,24.5-24.5C49.5,11.5,38.5,0.5,25,0.5z M25,47.3 c-12.3,0-22.3-10-22.3-22.3S12.7,2.7,25,2.7s22.3,10,22.3,22.3S37.3,47.3,25,47.3z');
        closure4.path('M25,11.9c-7.2,0-13.1,5.9-13.1,13.1S17.8,38.1,25,38.1S38.1,32.2,38.1,25S32.2,11.9,25,11.9z M25,35.9 c-6,0-10.9-4.9-10.9-10.9S19,14.1,25,14.1S35.9,19,35.9,25S31,35.9,25,35.9z');
        closure4.attr('class', 'closure mechanicClosure');
        closure4.rect(2, 9).center(24.5, 9.5).attr('class', 'fill');
        closure4.rect(2, 9).center(24.5, 40).attr('class', 'fill');
        closure4.rect(2, 9).center(9.5, 24.5).rotate(90).attr('class', 'fill');
        closure4.rect(2, 9).center(40, 24.5).rotate(90).attr('class', 'fill');
        closure4.rect(2, 9).center(36, 14).rotate(45).attr('class', 'fill');
        closure4.rect(2, 9).center(14, 14).rotate(135).attr('class', 'fill');
        closure4.rect(2, 9).center(14, 36).rotate(225).attr('class', 'fill');
        closure4.rect(2, 9).center(36, 36).rotate(315).attr('class', 'fill');
        closure4.center(centerFrontX, centerFrontY);
    }

    draw.viewbox(group.bbox());
}

drawSafe(400, 400, 400, '', 'standtresor');
